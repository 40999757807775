.job-offering {
    width: 55%;
    // max-width: 600px;
    background-color: #fff;
    margin: 6% auto;
    border-radius: 20px;
    padding: 25px 15px;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .job-offering {
        width: 80%;
    }
}

@media (min-width:1024px) and (max-width: 1900px) {
    .job-offering {
        width: 45%;
    }
  }

@media(min-width: 1901px){
    .job-offering {
        width: 45%;
        padding: 20px 15px;
        margin: 2% auto;
    }
}