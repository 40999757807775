.job-browser {
    .company-image {
        display: flex;
        width: 133px;
        justify-content: center;
        margin: auto;

        img {
            width: 100%;
            height: 100%;
        }

        .company-image-div {
            width: 80px;
            height: 80px;
            border-radius: 50px;
            background-color: rgb(184, 179, 179);
            color: #D63847;
            font-size: 40px;
            align-content: center;
            text-align: center;
        }
    }

    .job-listing {
        .job {
            text-align: center;
            font-size: 25px;
        }
    }
}

.footer-job-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .view-job {
        border: none;
        width: 65%;
        max-width: 400px;
        height: 43px;
        background-image: linear-gradient(to right, #652973 0%, #972F60 50%, #D63847 100%);
        border-radius: 25px;
        font-size: 20px;
        font-weight: bolder;
        color: white;
        cursor: pointer;
    }
    .view-job:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }

    .skip-job {
        width: 30%;
        max-width: 300px;
        height: 30px;
        background-color: #b0bec5;
        font-size: 13px;
        border-radius: 25px;
        border: none;
        font-weight: bold;
        color: rgb(99, 98, 98);
        cursor: pointer;
    }
}

hr {
    width: 20%;
    /* Adjust width as needed */
    height: 2px;
    /* Adjust height for thickness */
    background-color: rgb(207, 204, 204);
    /* Set to grey color */
    border: none;
    /* Remove default border */
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh; // Adjust as needed
    font-size: 1.5rem;
}