@import url('https://fonts.cdnfonts.com/css/aller');

html,
body {
  background-image: linear-gradient(to right, #652973 0%, #972F60 50%, #D63847 100%);
  margin: 0;
  padding: 0;
  font-family: 'Aller', sans-serif;
  width: 100%;
}
.root{
  height: 100%;
  width: 100%;
}

body::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #C85994;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #903F91;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #C85994;
  /* creates padding around scroll thumb */
}