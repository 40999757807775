.progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .progress-bar {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;

        .step {
            padding: 10px 20px;
            color: white;
            font-weight: bold;
            text-align: center;
            position: relative;
            z-index: 1;
            margin-right: -10px;
            background-color: #B0BEC5;
            /* Default grey for uncompleted steps */
            box-shadow: -10px 0 0 0 white;
            /* White border on the right */
        }

        .step.completed {
            background-color: #C85994;
            /* Color for completed steps */
            color: white;
            z-index: 2;
        }

        .step:first-child {
            flex: 1;
            /* Smaller width */
            clip-path: polygon(1px 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 1px 100%, 0 50%);
            border-radius: 20px 0 0 20px;
            /* Curved left side for the first step */
        }

        .step:nth-child(2),
        .step:nth-child(3) {
            flex: 1;
            /* Smaller width */
            clip-path: polygon(0 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 0 100%, 15px 50%);
        }

        .step:last-child {
            flex: 3;
            /* Larger width */
            clip-path: polygon(0 0, calc(100% - 1px) 0, 100% 50%, calc(100% - 1px) 100%, 0 100%, 15px 50%);
            margin-right: 0;
            border-radius: 0 20px 20px 0;
            /* Curved right side for the last step */
            box-shadow: none;
            /* No shadow for the last step */
        }

        .step:not(:last-child):after {
            content: "";
            position: absolute;
            right: -10px;
            top: 0;
            height: 100%;
            width: 10px;
            background-color: white;
            /* White color for the overlap */
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 50%, 10px 0);
        }
    }

    .para-sponser {
        font-size: 28px;
        text-align: center;
        font-weight: 600;
    }
}
@media only screen and (max-width: 767px) {
    .progress-container .progress-bar .step:last-child {
        height: 20px;
        font-size: 14px;
    }
}